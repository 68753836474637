<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper p-0 bg-transparent overflow-hidden">
        <div class="">
          <!-- <RefundFilters  @clearFilter="handleClearFilter" @showChange="gettingValueChanged" /> -->
          <RefundCards @showChange="gettingValueChanged" :totalStatus="totalStatus" :showLoader="showLoader"/>
        </div>
        <div class="home-registry-wrapper">
          <RefundTop :count="count" :searched="filter.search"  @clearFilter="handleClearFilter"/>
          <div
            v-if="showLoader == 1"
            class="lds-dual-ring-registry"
            id="loading"
          ></div>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade"
              id="registry-table"
              v-bind:class="{ active: tabindex == 0, show: tabindex == 0 }"
              role="tabpanel"
              aria-labelledby="registry-table-tab"
            >
              
              <RefundTable :message="parentMessage" :refundOrder="refundOrder" :showLoader="showLoader" :isFilterApplied="isFilterApplied"/>
            </div>
          </div>
          <RefundBottom  :page="pager" :count="count" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import RefundTop from "../components/refunds/RefundTop.vue";
import RefundTable from "../components/refunds/RefundTable.vue";
import RefundBottom from "../components/refunds/RefundBottom.vue";
import Navbar from "../components/header/Navbar.vue";
// import RefundFilters from "../components/refunds/RefundFilter.vue";
import RefundCards from "../components/refunds/RefundCards.vue";

export default {
  name: "RefundOrder",

  components: {
    Navbar,
    LeftMenu,
    RefundTop,
    RefundTable,
    RefundBottom,
    // RefundFilters,
    RefundCards,
  },

  data: function() {
    return {
      parentMessage: false,
      errorMessage: null,
      refundOrder: [],
      showLoader: 0,
      page: 1,
      pager: {},
      perPage: 3,
      pages: [],
      success: null,
      error: null,
      count: 0,
      tabindex: 0,
      images: [
        "https://i.wifegeek.com/200426/f9459c52.jpg",
        "https://i.wifegeek.com/200426/5ce1e1c7.jpg",
        "https://i.wifegeek.com/200426/5fa51df3.jpg",
        "https://i.wifegeek.com/200426/663181fe.jpg",
        "https://i.wifegeek.com/200426/2d110780.jpg",
        "https://i.wifegeek.com/200426/e73cd3fa.jpg",
        "https://i.wifegeek.com/200426/15160d6e.jpg",
        "https://i.wifegeek.com/200426/d0c881ae.jpg",
        "https://i.wifegeek.com/200426/a154fc3d.jpg",
        "https://i.wifegeek.com/200426/71d3aa60.jpg",
        "https://i.wifegeek.com/200426/d17ce9a0.jpg",
        "https://i.wifegeek.com/200426/7c4deca9.jpg",
        "https://i.wifegeek.com/200426/64672676.jpg",
        "https://i.wifegeek.com/200426/de6ab9c6.jpg",
        "https://i.wifegeek.com/200426/d8bcb6a7.jpg",
        "https://i.wifegeek.com/200426/4085d03b.jpg",
        "https://i.wifegeek.com/200426/177ef44c.jpg",
        "https://i.wifegeek.com/200426/d74d9040.jpg",
        "https://i.wifegeek.com/200426/81e24a47.jpg",
        "https://i.wifegeek.com/200426/43e2e8bb.jpg",
      ],
      filter: {},
      totalStatus: {},
      pageSize: "15",
      isFilterApplied: false, // New state to check if filters are applied

    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions("auth", ["sendVerifyResendRequest"]),
    gettingValueChanged(value) {
      this.parentMessage = value;
    },
    handleClearFilter(clearedFilter) {
      this.filter = clearedFilter; // Update filter with cleared filter object

      // Check if any filter is applied (non-empty values in the filter object)
      const isFilterApplied = Object.keys(this.filter).some(key => {
        const value = this.filter[key];
        // Check if value is a string and non-empty after trimming, or if value is not empty
        return (typeof value === 'string' && value.trim() !== "") || (typeof value === 'number' && value !== null && value !== "");
      });

      if (isFilterApplied) {
        // If any filter is applied, reset to page 1
        this.page = 1; 
        this.isFilterApplied = true;

      } else {
        // If no filter is applied (filters are cleared), fetch page from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page') || 1; // Default to page 1 if no page parameter
        this.page = parseInt(page, 10);
        this.isFilterApplied = false;

        // Log the page number to the console
        console.log("Page from URL:", this.page);
      }

      this.getDataRegistry(); // Fetch data based on cleared filters
    },
    getDataRegistry() {
      this.showLoader = 1;
      const user_id = this.user && this.user.id ? this.user.id : localStorage.getItem('loginUserId');

      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAllRefunds?page=${this.page}&size=${
            this.pageSize ? this.pageSize : ""}&Customer_id=${
            this.filter.customer_id ? this.filter.customer_id : ""
          }&customer_name=${
            this.filter.customer_name ? this.filter.customer_name : ""
          }&order_number=${
            this.filter.order_number ? this.filter.order_number : ""
          }&refund_id=${
            this.filter.refund_id ? this.filter.refund_id : ""
          }&Risk_level=${
            this.filter.risk_level ? this.filter.risk_level : ""
          }&search=${
            this.filter.search ? this.filter.search : ""
          }&email=${
            this.filter.email ? this.filter.email : ""
          }&phone=${
            this.filter.phone ? this.filter.phone : ""
          }&UserID=${user_id }`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.refundOrder = response.data.data.listing;
            this.pager = response.data.data;
            this.count = response.data.data.totalItems;
            this.totalStatus = response.data.data;
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
  },
  watch: {
    "$route.query.page": {
      
        
          
      immediate: true,
      handler(newPage) {
        newPage = parseInt(newPage) || 1;
        if (
          newPage !== this.page
        ) {
          this.page = newPage;
          this.showLoader = 1;
          const user_id = this.user && this.user.id ? this.user.id : localStorage.getItem('loginUserId');

          axios
          .get(
          `${process.env.VUE_APP_API_URL}getAllRefunds?page=${this.page}&size=${
            this.pageSize ? this.pageSize : ""}&Customer_id=${
            this.filter.customer_id ? this.filter.customer_id : ""
          }&order_number=${
            this.filter.order_number ? this.filter.order_number : ""
          }&refund_id=${
            this.filter.RefundID ? this.filter.RefundID : ""
          }&Risk_level=${
            this.filter.risk_level ? this.filter.risk_level : ""
          }&search=${
            this.filter.search ? this.filter.search : ""
          }&email=${
            this.filter.email ? this.filter.email : ""
          }&phone=${
            this.filter.phone ? this.filter.phone : ""
          }&UserID=${user_id }`,
          {}
        )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.refundOrder = response.data.data.listing;
                this.pager = response.data.data;
                this.count = response.data.data.totalItems;
                this.totalStatus = response.data.data;
                this.showLoader = 0;
              } else {
                this.errorMessage = response.data.message;
                this.showLoader = 0;
              }
            });
        }
      },
    },
  },
  mounted() {
    this.getDataRegistry();
    this.$root.$on("filterOrders", (data) => {
      this.filter = data;
      this.getDataRegistry();
    });
    
    this.$root.$on("searchForRefundOrders", (data) => {
      this.filter = data;
      // Check if search is empty or contains only whitespace
      if (this.filter && this.filter.search && this.filter.search.trim() !== "") {
        this.page = 1;
        this.isFilterApplied = true;
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page') || 1;  // Default to page 1 if no page parameter
        this.page = parseInt(page, 10);
        this.isFilterApplied = false;

        console.log("Page from URL:", this.page);
      }

      this.getDataRegistry();
    });
    this.$root.$on("viewHandle", (data) => {
      this.tabindex = data;
    });
  },
  created() {},
};
</script>
