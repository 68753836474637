<template>
  <div class="app-page">
    <PaymentModal />
    <div v-if="showLoader == 1" class="lds-dual-ring-registry" id="loading"></div>

    <div v-if="subscriptionPlans.length > 0" class="row justify-content-center align-items-center h-100">

      <div v-for="plan in subscriptionPlans" :key="plan.id" class="col-md-4 col-lg-4 col-xl-3 my-2 my-md-0 py-2 py-md-0">
        <div class="subscript-card">

          <div class="subscript-card-content">
            <div class="sub-card-tag">
              <p v-if="plan.name === 'Free'">Trial</p>
              <p v-if="plan.name === 'Bronze'">Regular</p>
              <p v-if="plan.name === 'Premium'">Popular</p>
            </div>

            <div class="sub-card-heading">
              <h2>{{ plan.name }}</h2>
              <p>{{ plan.description }}</p>
            </div>

            <div class="sub-card-amount">
              <h2>${{ plan.amount }}</h2>
              <p>/Month</p>
            </div>

            <div class="sub-card-detail">
              <p>Subscription Details</p>
              <ul>
                <li>
                  <img src="../../assets/images/shock-sjield/check-Icon.png">
                  <p>{{ plan.transactions }} Transaction</p>
                </li>
                <li>
                  <img src="../../assets/images/shock-sjield/check-Icon.png">
                  <p>${{ plan.perTransaction }} Per Transaction</p>
                </li>
                <li v-if="plan.name === 'Free'">
                  <img src="../../assets/images/shock-sjield/check-Icon.png">
                  <p>Limited Support</p>
                </li>
                <li v-else-if="plan.name === 'Premium'">
                  <img src="../../assets/images/shock-sjield/check-Icon.png">
                  <p>Premium Support</p>
                </li>
                <li v-else>
                  <img src="../../assets/images/shock-sjield/check-Icon.png">
                  <p>Dedicated Support</p>
                </li>
              </ul>
            </div>

            <div class="sub-card-button px-3">

              <div v-if="plan.name === 'Free'">
                <!-- Button for Free Trial -->
                <button type="button" @click="activateFreeTrial(plan)" class="btn button custom-btn mb-3"
                  v-if="subscriptionDetail?.user?.trial_subscription === '0' || subscriptionDetail?.user?.trial_subscription === 0">
                  Activate Free Trial
                </button>

                <!-- Button for Already Activated Trial -->
                <button
                  v-else-if="(subscriptionDetail?.user?.trial_subscription !== '0' && subscriptionDetail?.user?.trial_subscription !== 0) && (subscriptionDetail?.user?.trial_transaction > 0)"
                  type="button" class="btn button custom-btn mb-3" disabled>
                  Activated
                </button>

                <!-- Button for Expired Trial -->
                <button
                  v-else-if="subscriptionDetail?.user?.trial_subscription === 1 && subscriptionDetail?.user?.trial_transaction === 0"
                  type="button" class="btn button custom-btn mb-3" disabled>
                  Expired
                </button>
              </div>


              <div v-else>
                <!-- Button for Updating Subscription -->
                <button type="button" @click="payment(plan)" class="button custom-btn mb-3"
                  v-if="hasPurchased(plan.id)">
                  Update Subscription
                </button>

                <!-- Button for Adding Subscription -->
                <button type="button" @click="payment(plan)" class="btn button custom-btn mb-3"
                  v-else-if="(plan.name === 'Bronze' || plan.name === 'Premium') && !hasPurchased(plan.id)">
                  Buy Subscription
                </button>

                <!-- Button for Removing Subscription (for Admin) -->
                <button type="button" @click="deleteSubscription(plan.id)" class="button custom-btn mb-3"
                  v-if="isAdmin">
                  Remove Subscription
                </button>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>

  </div>

</template>

<script>
import axios from "axios";
import PaymentModal from "../payment/PaymentModal.vue";
import { mapGetters, mapActions } from "vuex";


export default {
  name: "SubcscriptionCards",

  components: {
    PaymentModal,
  },
  data() {
    return {
      showForm: false,
      subscriptionPlans: [],
      subscriptionDetail: [],
      purchases: [], // Add purchases array
      isAdmin: false,
      showCardDetailModal: false,
      showLoader: 0,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),

  },

  methods: {
    ...mapActions("auth", ["sendVerifyResendRequest"]),
    hasPurchased(planId) {
  // Check if 'purchases' is defined and is an array
    return Array.isArray(this.purchases) && this.purchases.some(purchase => purchase.subscription_plan_id === planId);
  },
    payment(plan) {
      // Emit event to open PaymentModal11 with package info
      this.$root.$emit("openPaymentModal", {
        title: plan.name,
        amount: plan.amount,
        description: plan.description,
        transactions: plan.transactions,
        perTransaction: plan.perTransaction,
        package_id: plan.id
      });
    },
    
    activateFreeTrial(plan) {
      this.showLoader = 1;

      // Prepare the request payload
      const payload = {
          plan_id: plan.id // Sending the plan ID to the backend
      };

      axios
          .post(process.env.VUE_APP_API_URL + "payment/free-subscription", payload) // Updated API endpoint
          .then((response) => {
              if (response.data.statusCode === 200) {
                  // Show success message
                  this.$swal({
                      title: "Subscription Activated!",
                      text: response.data.message,
                      icon: "success",
                      buttons: true, // Enable buttons (OK)
                  }).then(() => {
                      // After the Swal alert is closed, check the 'shopifyStoreCount' in local storage
                      const shopifyStoreCount = localStorage.getItem("shopifyStoreCount");
                      if (shopifyStoreCount === "0") {
                          // If count is 0, redirect to the login page
                          this.$router.push({ name: "Login" });
                      }
                  });

                  // Emit the 'reNewSubs' event to notify other components
                  this.$root.$emit("reNewSubs");

                  // Close modal if the element exists
                  const elem = this.$refs.closeModalAddCategory;
                  if (elem) {
                      elem.click();
                  }
              } else {
                  // Handle error response from the server
                  this.$swal("Error!", response.data.message || "Something went wrong", "error");
              }
          })
          .catch((error) => {
              // Handle any other errors
              this.$swal("Error!", error.response?.data?.message || "Something went wrong", "error");
          })
          .finally(() => {
              // Hide loader after the operation is complete
              this.showLoader = 0;
          });
  },

    fetchSubscriptionPlans() {
      this.showLoader = 1;
      axios
        .get(`${process.env.VUE_APP_API_URL}getAllSubscriptionPlans`)
        .then((response) => {
          if (response.data.statusCode === 200) {
            // Custom sorting based on the name order: Free, Bronze, Premium
            this.subscriptionPlans = response.data.data.sort((a, b) => {
              const order = ['Free', 'Bronze', 'Premium' ];
              return order.indexOf(a.name) - order.indexOf(b.name);
            });
            this.showLoader = 0;
          }
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to get subscription plan.");
          this.showLoader = 0;
        });
    },

    fetchUserSubscriptionPlans() {
      this.showLoader = 1;
      const user_id = this.user?.id || localStorage.getItem('loginUserId');

      let formData = new FormData();
      formData.append('user_id', user_id);

      axios
        .post(`${process.env.VUE_APP_API_URL}getUserSubscriptionDetail`, formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.subscriptionDetail = response.data.data;
            this.purchases = response.data.data.purchases; // Add purchases to the array

          } else {
            console.log(response.data.message || "Failed to fetch subscription plans.");
          }
          this.showLoader = 0;
        })
        .catch((error) => {
          console.log("Failed to get subscription plan. Please try again later.", error);
          this.showLoader = 0;
        });
    },
  },
  mounted() {
    this.$root.$on("openPaymentModal", () => {
      // this.openPaymentModal();
    });
    this.fetchUserSubscriptionPlans();

    this.fetchSubscriptionPlans();
    

  },
};
</script>

<style></style>
