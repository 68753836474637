<template>
  <div class="container-fluid filter-content">
    <div class="row no-gutters p-0 px-1 mb-2 justify-content-between">
      <div class="col-12 d-flex">
        <h5 class="mb-md-1">Refunds</h5>
      </div>
    </div>

    <div>
      <div class="row mb-2 p-1">
        <div class="col-12 col-md-4 mb-2 mb-md-0 p-0">
          <div class="h-100 pr-0 pr-md-2">
            <div class="order-card">
              <div class="d-flex justify-content-between align-items-center">
                 <div class="order-card-icon">
                  <img src="../../assets/images/icon/refund.png">
                </div>
              
                </div>


                <div class="mt-3" v-if="showLoader == 1">
                  <content-placeholders :rounded="true" >
                    <content-placeholders-heading :img="false" />
                  </content-placeholders>
                </div>

                <div v-else class="order-card-content mt-2">
                  <div class="">
                    <span>Total Refunds</span>
                    <p v-if="totalStatus?.totalItems">{{ totalStatus?.totalItems }}</p>
                    <p v-else>0</p>
                  </div>

                  <!-- <div class="col">
                    <span>Pending</span>
                    <p>5</p>
                  </div> -->
                  
                  <div class="">
                    <span>Total Extremely High</span>
                    <p v-if="totalStatus?.totalStatus?.TotalExtremelyHigh">{{ totalStatus?.totalStatus?.TotalExtremelyHigh }}</p>
                    <p v-else >0</p>
                  </div>
                </div>

            </div>

          </div>
        </div>

        <div class="col-12 col-md-4 mb-2 mb-md-0 p-0">
          <div class="h-100 pr-0 pr-md-2">
            <div class="order-card">
              <div class="d-flex justify-content-between align-items-center">
                 <div class="order-card-icon">
                  <img src="../../assets/images/icon/refund.png">
                </div>
              
                </div>


                <div class="mt-3" v-if="showLoader == 1">
                  <content-placeholders :rounded="true" >
                    <content-placeholders-heading :img="false" />
                  </content-placeholders>
                </div>

                <div v-else class="order-card-content mt-2">
                  <div class="">
                    <span>Total High</span>
                    <p v-if="totalStatus?.totalStatus?.TotalHigh">{{ totalStatus?.totalStatus?.TotalHigh }}</p>
                    <p v-else>0</p>
                  </div>

                  <!-- <div class="col">
                    <span>Pending</span>
                    <p>5</p>
                  </div> -->
                  
                  <div class="">
                    <span>Total Medium</span>
                    <p v-if="totalStatus?.totalStatus?.TotalMedium">{{ totalStatus?.totalStatus?.TotalMedium }}</p>
                    <p v-else>0</p>
                  </div>
                </div>

            </div>

          </div>
        </div>

        <div class="col-12 col-md-4 mb-2 mb-md-0 p-0">
          <div class="h-100 pr-0 pr-md-2">
            <div class="order-card">
              <div class="d-flex justify-content-between align-items-center">
                 <div class="order-card-icon">
                  <img src="../../assets/images/icon/refund.png">
                </div>
              
                </div>


                <div class="mt-3" v-if="showLoader == 1">
                  <content-placeholders :rounded="true" >
                    <content-placeholders-heading :img="false" />
                  </content-placeholders>
                </div>

                <div v-else class="order-card-content mt-2">
                  <div class="">
                    <span>Total Low</span>
                    <p v-if="totalStatus?.totalStatus?.TotalLow">{{ totalStatus?.totalStatus?.TotalLow }}</p>
                    <p v-else>0</p>
                  </div>

                  <!-- <div class="col">
                    <span>Pending</span>
                    <p>5</p>
                  </div> -->
                  
                  <div>
                    <span>{{ totalStatus?.totalStatus?.Others > 1 ? 'Other`s' : 'Other' }}</span>
                    <p v-if="totalStatus?.totalStatus?.Others">{{ totalStatus?.totalStatus?.Others }}</p>
                    <p v-else>0</p>
                  </div>

                </div>

            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "RefundCards",
  data() {
    return {
      visible: true,
    };
  },
  props: {
    totalStatus: {
      type: Object,
      default: () => ({}),
    },
    showLoader: {
      type: Number, // Use Number to accept 0 or 1
      default: 1,   // Default value is 0
    }

  },

  methods: {
  },
  mounted() {
    this.$emit("showChange", this.visible);
  },
};
  

</script>


<style scoped>
.order-card {
    border-radius: 15px;
    padding: 10px;
    background: var(--registry-wrapper);
    color: var(--text-primary-color);
    height: 100%;
    /* margin-bottom: 10px; */
}
.order-card-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between
  }
.order-card-content span{
  color: #8B8D97;
  font-size: 14px;
  text-align: center;
  }
.order-card-content p{
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  }
.dropdown-toggle {
  color: #8B8D97;
  }
.dropdown-toggle:focus {
  box-shadow: none!important;
  }
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--light-sky)!important;
  }
</style>
