<template>
    <div>
      <h2>Notification Table</h2>
      <table>
        <thead>
          <tr>
            <th>Notification ID</th>
            <th>Message</th>
            <th>Timestamp</th>
            <!-- Add more table headers as needed -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="notification in notifications" :key="notification.NotificationID">
            <td>{{ notification.NotificationID }}</td>
            <td>{{ notification.Message }}</td>
            <td>{{ notification.Timestamp }}</td>
            <!-- Add more table data columns as needed -->
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    name: "NotificationTable",
    props: {
      notifications: Array, // Pass the notifications data as a prop
    },
  };
  </script>
  
  <style>
  /* Add styling for the table as needed */
  </style>
  