<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper p-0 bg-transparent overflow-hidden">
        <div class="">
          <!-- <CustomersFilter  @clearFilter="handleClearFilter" @showChange="gettingValueChanged" /> -->
          <CustomersCard  @clearFilter="handleClearFilter" @showChange="gettingValueChanged" :showLoader="showLoader" :totalStatus="totalStatus" />
        </div>
        <div class="home-registry-wrapper">
          <CustomersTop :count="count" :searched="filter.search" @clearFilter="handleClearFilter" />
          <div
            v-if="showLoader == 1"
            class="lds-dual-ring-registry"
            id="loading"
          ></div>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade"
              id="registry-table"
              v-bind:class="{ active: tabindex == 0, show: tabindex == 0 }"
              role="tabpanel"
              aria-labelledby="registry-table-tab"
            >
              
              <CustomersTable :message="parentMessage" :customerList="customerList" :showLoader="showLoader" :isFilterApplied="isFilterApplied"/>
            </div>
          </div>
          <CustomersBottom :page="pager" :count="count" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import CustomersTop from "../components/customers/CustomersTop.vue";
import CustomersTable from "../components/customers/CustomersTable.vue";
import CustomersBottom from "../components/customers/CustomersBottom.vue";
import Navbar from "../components/header/Navbar.vue";
// import CustomersFilter from "../components/customers/CustomersFilter.vue";
import CustomersCard from "../components/customers/CustomerCards.vue";

export default {
  name: "Customers",

  components: {
    Navbar,
    LeftMenu,
    CustomersTop,
    CustomersTable,
    CustomersBottom,
    // CustomersFilter,
    CustomersCard,
  },

  data: function() {
    return {
      parentMessage: false,
      errorMessage: null,
      customerList: [],
      showLoader: 0,
      page: 1,
      pager: {},
      perPage: 3,
      pages: [],
      success: null,
      error: null,
      count: 0,
      tabindex: 0,
      filter: {},
      pageSize: "15",
      totalStatus: {},
      isFilterApplied: false, // New state to check if filters are applied

    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions("auth"),
    gettingValueChanged(value) {
      this.parentMessage = value;
    },
    handleClearFilter(clearedFilter) {
      this.filter = clearedFilter;

      // Check if any filter is applied (non-empty values in the filter object)
      const isFilterApplied = Object.keys(this.filter).some(key => {
        const value = this.filter[key];
        // Check if value is a string and non-empty after trimming, or if value is not empty
        return (typeof value === 'string' && value.trim() !== "") || (typeof value === 'number' && value !== null && value !== "");
      });

      if (isFilterApplied) {
        // If any filter is applied, reset to page 1
        this.page = 1;
        this.isFilterApplied = true;

      } else {
        // If no filter is applied (filters are cleared), fetch page from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page') || 1; // Default to page 1 if no page parameter
        this.page = parseInt(page, 10);
        this.isFilterApplied = false;

        // Log the page number to the console
        console.log("Page from URL:", this.page);
      }

      this.getDataRegistry(); // Fetch data based on cleared filters or URL page
    },

    getDataRegistry() {
      this.showLoader = 1;
      const user_id = this.user && this.user.id ? this.user.id : localStorage.getItem('loginUserId');

      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAllCustomers?page=${this.page}&size=${
            this.pageSize ? this.pageSize : ""}&id=${
            this.filter.customer_id ? this.filter.customer_id : ""
          }&first_name=${
            this.filter.name ? this.filter.name : ""
          }&state=${
            this.filter.state ? this.filter.state : ""
          }&search=${
            this.filter.search ? this.filter.search : ""
          }&email=${
            this.filter.email ? this.filter.email : ""
          }&phone=${
            this.filter.phone ? this.filter.phone : ""
          }&UserID=${
          user_id }`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.customerList = response.data.data.listing;
            this.pager = response.data.data;
            this.count = response.data.data.totalItems;
            this.totalStatus = response.data.data;
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
  },
  watch: {
    "$route.query.page": {
      immediate: true,
      handler(newPage) {
        newPage = parseInt(newPage) || 1;
        if (
          newPage !== this.page
        ) {
          this.page = newPage;
          this.showLoader = 1;
          const user_id = this.user && this.user.id ? this.user.id : localStorage.getItem('loginUserId');
          axios
          .get(
          `${process.env.VUE_APP_API_URL}getAllCustomers?page=${this.page}&size=${
            this.pageSize ? this.pageSize : ""}&id=${
            this.filter.customer_id ? this.filter.customer_id : ""
          }&first_name=${
            this.filter.name ? this.filter.name : ""
          }&state=${
            this.filter.state ? this.filter.state : ""
          }&search=${
            this.filter.search ? this.filter.search : ""
          }&email=${
            this.filter.email ? this.filter.email : ""
          }&phone=${
            this.filter.phone ? this.filter.phone : ""
          }&UserID=${
          user_id }`,
          {}
        )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.customerList = response.data.data.listing;
                this.pager = response.data.data;
                this.count = response.data.data.totalItems;
                this.totalStatus = response.data.data;
                this.showLoader = 0;
              } else {
                this.errorMessage = response.data.message;
                this.showLoader = 0;
              }
            });
        }
      },
    },
  },
  mounted() {
    this.getDataRegistry();
    this.$root.$on("filterOrders", (data) => {
      this.filter = data;
      console.log(this.filter);
    

      if (data || Object.keys(data).length > 0) {
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page') || 1;  
        this.page = parseInt(page, 10);

        console.log("Page from URL:", this.page);
      } else {
        this.page = 1;
      }

      this.getDataRegistry();
    });


    this.$root.$on("searchForCustomer", (data) => {
      this.filter = data;
      console.log(this.filter);

      // Check if search is empty or contains only whitespace
      if (this.filter && this.filter.search && this.filter.search.trim() !== "") {
        this.page = 1;
        this.isFilterApplied = true;

      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page') || 1;  // Default to page 1 if no page parameter
        this.page = parseInt(page, 10);
        this.isFilterApplied = false;

        console.log("Page from URL:", this.page);
      }

      this.getDataRegistry();
    });
    this.$root.$on("viewHandle", (data) => {
      this.tabindex = data;
    });
  },
  created() {},
};
</script>
