<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper p-0 bg-transparent">
        <div class="row justify-content-end m-0 pr-2" v-if="isAdmin">
          <div class="add-subscription">
            <button type="button" class="btn" @click="createSubscriptionsPage">
              Add Subscription
            </button>
          </div>
        </div>

        <PaymentModal v-if="showAddPaymentModal" />


        <!-- Purchase Details Section -->
        <div v-if="purchases && purchases.length > 0" class="purchase-details-section">
          <hr>
          <h2 class="text-center">Purchase Details</h2>
          <hr>
          <div class="row m-0">
          <!-- Loop through purchases and display each purchase as a card -->
          <div class="col-md-6 col-lg-4 p-2" v-for="purchase in purchases" :key="purchase.id">
            <div class="card card-purchase">
              <div class="card-header">
                <h5>Plan Name: {{ purchase?.subscriptionPlan?.name || 'N/A' }}</h5>
              </div>
              <div class="card-body">
                <p><strong>Amount:</strong> ${{ purchase.amount || '0' }}</p>
                <p><strong>Card:</strong> **** **** **** {{ purchase.card_last_four || 'N/A' }}</p>
                <p v-if="purchase?.subscriptionPlan?.name === 'Free'"><strong >Remaining Transactions:</strong> {{ subscriptionDetail.user.trial_transaction || 'N/A' }}</p>
                <p v-else><strong>Remaining Transactions:</strong> {{ subscriptionDetail.user.transactions || 'N/A' }}</p>
                <p><strong>Per Transaction Rate:</strong> ${{ purchase.subscriptionPlan?.perTransaction || '0' }}</p>
              </div>
            </div>
          </div>
        </div>
        </div>

        <!-- Loader and Subscription Plans Section -->
        <div v-if="showLoader == 1" class="lds-dual-ring-registry" id="loading"></div>

        <div v-if="subscriptionPlans.length > 0">
          <div>
            <hr>
            <h2 class="text-center">Our {{ packageLabel }}</h2>
            <hr>
          </div>
          <div class="row m-0">
            <div class="col-md-6 col-lg-4 p-2  " v-for="plan in subscriptionPlans" :key="plan.id">

              <div class="subscript-card">

                <div class="subscript-card-content">
                  <div class="sub-card-tag">
                    <p v-if="plan.name === 'Free'">Trial</p>
                    <p v-if="plan.name === 'Bronze'">Regular</p>
                    <p v-if="plan.name === 'Premium'">Popular</p>
                  </div>

                  <div class="sub-card-heading">
                    <h2>{{ plan.name }}</h2>
                    <p>{{ plan.description }}</p>
                  </div>

                  <div class="sub-card-amount">
                    <h2>${{ plan.amount }}</h2>
                    <p>/Month</p>
                  </div>

                  <div class="sub-card-detail">
                    <p>Subscription Details</p>
                    <ul>
                      <li>
                        <img src="../assets/images/shock-sjield/check-Icon.png">
                        <p>{{ plan.transactions }} Transaction</p>
                      </li>
                      <li>
                        <img src="../assets/images/shock-sjield/check-Icon.png">
                        <p>${{ plan.perTransaction }} Per Transaction</p>
                      </li>
                      <li v-if="plan.name === 'Free'">
                        <img src="../assets/images/shock-sjield/check-Icon.png">
                        <p>Limited Support</p>
                      </li>
                      <li v-else-if="plan.name === 'Premium'">
                        <img src="../assets/images/shock-sjield/check-Icon.png">
                        <p>Premium Support</p>
                      </li>
                      <li v-else>
                        <img src="../assets/images/shock-sjield/check-Icon.png">
                        <p>Dedicated Support</p>
                      </li>
                    </ul>
                  </div>



                  <div class="sub-card-button px-3">

                    <div v-if="plan.name === 'Free'">
                      <!-- Button for Free Trial -->
                      <button type="button" @click="activateFreeTrial(plan)" class="btn button custom-btn mb-3"
                        v-if="User && subscriptionDetail?.user?.trial_subscription === '0' || subscriptionDetail?.user?.trial_subscription === 0">
                        Activate Free Trial
                      </button>

                      <!-- Button for Already Activated Trial -->
                      <button
                        v-else-if="(subscriptionDetail?.user?.trial_subscription !== '0' && subscriptionDetail?.user?.trial_subscription !== 0) && (subscriptionDetail?.user?.trial_transaction > 0)"
                        type="button" class="btn button custom-btn mb-3" disabled>
                        Activated
                      </button>

                      <!-- Button for Expired Trial -->
                      <button
                        v-else-if="subscriptionDetail?.user?.trial_subscription === 1 && subscriptionDetail?.user?.trial_transaction === 0"
                        type="button" class="btn button custom-btn mb-3" disabled>
                        Expired
                      </button>
                    </div>


                    <div v-else>
                      <!-- Button for Updating Subscription -->
                      <button
                        type="button"
                        @click="payment(plan)"
                        class="button custom-btn mb-3"
                        v-if="User && hasPurchased(plan.id)"
                      >
                        Update Subscription
                      </button>

                      <!-- Button for Adding Subscription -->
                      <button
                        type="button"
                        @click="payment(plan)"
                        class="btn button custom-btn mb-3"
                        v-else-if="(plan.name === 'Bronze' || plan.name === 'Premium') && User && !hasPurchased(plan.id)"
                      >
                        Buy Subscription
                      </button>

                      <!-- Button for Removing Subscription (for Admin) -->
                      <button
                        type="button"
                        @click="deleteSubscription(plan.id)"
                        class="button custom-btn mb-3"
                        v-if="isAdmin"
                      >
                        Remove Subscription
                      </button>
                    </div>


                  </div>


                </div>

              </div>

            </div>
          </div>
        </div>

        <div v-if="showLoader == 0 && subscriptionPlans.length == 0"
          class="w-100 d-flex justify-content-center align-items-center">
          <p>There is no subscription added yet.</p>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import PaymentModal from "../components/payment/PaymentModal.vue";

export default {
  name: "Subscriptions",

  components: {
    Navbar,
    LeftMenu,
    PaymentModal,
  },

  data() {
      return {
        showForm: false,
        showAddPaymentModal: true,
        subscriptionPlans: {},
        subscriptionDetail: [],
        purchases: [],
        User: false,
        isAdmin: false,
        showCardDetailModal: false,
        showLoader: 0,
      };
    },

  computed: {
    ...mapGetters("auth", ["user"]),
    packageLabel() {
      return this.subscriptionPlans.length === 1 ? "Package" : "Packages";
    },
  },

  methods: {
    hasPurchased(planId) {
  // Check if 'purchases' is defined and is an array
    return Array.isArray(this.purchases) && this.purchases.some(purchase => purchase.subscription_plan_id === planId);
  },
    payment(plan) {
      // Emit event to open PaymentModal11 with package info
      this.$root.$emit("openPaymentModal", {
        title: plan.name,
        amount: plan.amount,
        description: plan.description,
        transactions: plan.transactions,
        perTransaction: plan.perTransaction,
        package_id: plan.id
      });
    },
  activateFreeTrial(plan) {
      this.showLoader = 1;

      // Prepare the request payload
      const payload = {
          plan_id: plan.id // Sending the plan ID to the backend
      };

      axios
          .post(process.env.VUE_APP_API_URL + "payment/free-subscription", payload) // Updated API endpoint
          .then((response) => {
              if (response.data.statusCode === 200) {
                  // Show success message
                  this.$swal({
                      title: "Subscription Activated!",
                      text: response.data.message,
                      icon: "success",
                      buttons: true, // Enable buttons (OK)
                  }).then(() => {
                      // After the Swal alert is closed, check the 'shopifyStoreCount' in local storage
                      const shopifyStoreCount = localStorage.getItem("shopifyStoreCount");
                      if (shopifyStoreCount === "0") {
                          // If count is 0, redirect to the login page
                          this.$router.push({ name: "Login" });
                      }
                  });

                  // Emit the 'reNewSubs' event to notify other components
                  this.$root.$emit("reNewSubs");

                  // Close modal if the element exists
                  const elem = this.$refs.closeModalAddCategory;
                  if (elem) {
                      elem.click();
                  }
              } else {
                  // Handle error response from the server
                  this.$swal("Error!", response.data.message || "Something went wrong", "error");
              }
          })
          .catch((error) => {
              // Handle any other errors
              this.$swal("Error!", error.response?.data?.message || "Something went wrong", "error");
          })
          .finally(() => {
              // Hide loader after the operation is complete
              this.showLoader = 0;
          });
  },


    // openPaymentModal() {
    //   this.showAddPaymentModal = true;
    // },
    deleteSubscription(id) {
      const subId = id;
      axios
        .delete(`${process.env.VUE_APP_API_URL}deleteSubscriptionPlan/${subId}`)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.subscriptionPlans.forEach((item, index) => {
              if (item.id === subId) {
                this.subscriptionPlans.splice(index, 1);
                this.$swal("Subscription Deleted!", "Subcription Plan deleted Sucessfully.", "success");

              }
            });
          }
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to delete subscription plan.");
        });
    },
    createSubscriptionsPage() {
      this.$router.push("/createSubscriptions");
    },
    ...mapActions("auth", ["sendVerifyResendRequest"]),
    gettingValueChanged(value) {
      this.parentMessage = value;
    },

    fetchSubscriptionPlans() {
    this.showLoader = 1;
    axios
      .get(`${process.env.VUE_APP_API_URL}getAllSubscriptionPlans`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          // Custom sorting based on the name order: Free, Bronze, Premium
          this.subscriptionPlans = response.data.data.sort((a, b) => {
            const order = ['Free', 'Bronze', 'Premium'];
            return order.indexOf(a.name) - order.indexOf(b.name);
          });
          this.showLoader = 0;
        }
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to get subscription plan.");
        this.showLoader = 0;
      });
  },

    fetchUserSubscriptionPlans() {
    this.showLoader = 1;
    const user_id = this.user && this.user.id ? this.user.id : localStorage.getItem('loginUserId');

    let formData = new FormData();
    formData.append('user_id', user_id);

    axios
      .post(`${process.env.VUE_APP_API_URL}getUserSubscriptionDetail`, formData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          this.subscriptionDetail = response.data.data;
          this.purchases = response.data.data.purchases; // Add purchases to the array
        } else {
          console.log(response.data.message || "Failed to fetch subscription plans.");
        }
        this.showLoader = 0;
      })
      .catch((error) => {
        console.log("Failed to get subscription plan. Please try again later.", error);
        this.showLoader = 0;
      });
  },



  },
  watch: {},
  mounted() {
    //   if(this.user.user.role_id === 1){
    //   this.isAdmin=true
    //   this.fetchSubscriptionPlans();
    //   }
    //  if (localStorage.getItem("permissions") == 2) {
    //       this.User= true;
    //       this.fetchSubscriptionPlans();
    //   }
    this.$root.$on("openPaymentModal", () => {
      // this.openPaymentModal();
    });
    this.$root.$on("reNewSubs", () => {
      this.fetchUserSubscriptionPlans();
    });
    if (localStorage.getItem("permissions") == 2) {
      this.User = true;
      this.fetchSubscriptionPlans();
      this.fetchUserSubscriptionPlans();
    } else if (localStorage.getItem("permissions") == 1) {
      this.isAdmin = true;
      this.fetchSubscriptionPlans();
    }


  },
  created() { },
};
</script>

<style scoped>
.purchase-details-section {
  margin-bottom: 30px;
}

.card-purchase {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.card-purchase .card-header {
  background-color: #f8f9fa;
  font-size: 1.2rem;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.card-purchase .card-body {
  padding: 15px;
}

</style>
