<template>
  <div class="table-container" v-bind:class="classObject">
    <!-- Render table only if customerList is not empty -->
    <b-table
      v-if="customerList.length > 0"
      :items="customerList"
      :fields="fields"
      hover
      responsive
      class="mt-3 b-datatable cursor-pointer"
    >
      <template v-slot:cell(name)="data">
        {{ data.item.first_name }} {{ data.item.last_name }}
      </template>

      <template v-slot:cell(created_at)="data">
        {{ data.item.created_at | moment("MMM D, YYYY") }}
      </template>

      <template v-slot:cell(phone)="data">
        {{ data.item.phone }}
      </template>

      <template v-slot:cell(email)="data">
        {{ data.item.email }}
      </template>
    </b-table>

    <!-- Show message when orderList is empty and filter is applied -->
    <div v-if="showLoader === 0 && customerList.length === 0 && isFilterApplied" class="row m-0 h-100 d-flex flex-column justify-content-center align-items-center">
      <div class="empty-list col-md-4">
        <div class="empty-icon">
          <img src="../../assets/images/icon/users.png">
        </div>

        <p class="mt-2 mb-0">No Results Found</p>
        <span class="text-center">Try adjusting your filters or clearing them.</span>
      </div>
    </div>

    <div v-if="showLoader === 0 && customerList.length === 0 && !isFilterApplied" class="row m-0 h-100 d-flex flex-column justify-content-center align-items-center">
      <div class="empty-list col-md-4">
        <div class="empty-icon">
          <img src="../../assets/images/icon/users.png">
        </div>
        <p class="mt-2 mb-0">No customers yet?</p>
        <span class="text-center">Add orders to your store to see customers here.</span>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "CustomersTable",

  data() {
    return {
      fields: [
        { key: 'id', label: 'Customer ID', sortable: true },
        { key: 'name', label: 'Name', sortable: true, class: 'text-center' },
        { key: 'phone', label: 'Phone', sortable: true, class: 'text-center' },
        { key: 'email', label: 'Email', sortable: true, class: 'text-center' },
        { key: 'created_at', label: 'Created At', sortable: true, class: 'text-center' },
      ],
    };
  },
  props: {
    customerList: {
      type: Array,
    },
    message: Boolean,
    showLoader: Number,
    isFilterApplied: Boolean, // Pass this from the parent component

  },
  computed: {
    classObject: function () {
      return {
        'reg-table': this.message,
        'reg2-table': !this.message,
      }
    },
  },
  methods: {},
};
</script>

