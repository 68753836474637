<template>
    <div class="">
      <div class="main-wrapper h-100">
        <div class="container-fluid h-100 p-0">
            <SubscriptionCards />
        </div>
      </div>
    </div>
  
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  import SubscriptionCards from "../components/subscription/SubscriptionCards.vue";
  
  export default {
    name: "SubscriptionCard",
  
    components: {
      SubscriptionCards,
    },
  
    data() {
        return {
          subscriptionPlans: [],
          showLoader: 0,
        };
      },
  
    computed: {
      ...mapGetters("auth", ["user"]),
      packageLabel() {
        return this.subscriptionPlans.length === 1 ? "Package" : "Packages";
      },
    },
  
    methods: {
      ...mapActions("auth", ["sendVerifyResendRequest"]),  
    },
    mounted() {
    },
    created() { },
  };
  </script>
  
  <style scoped>
  
  </style>
  