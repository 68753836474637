<template>
  <div class="registry-wrapper-top">
    <div class="heading">
      <h5>Customers</h5>
    </div>
    <div class="buttons">
      <div class="form-group mb-0 mx-2">
        <button class="search-icon" @click="search($event)">
          <img src="../../assets/images/search.png" alt="" />
        </button>
        <input
          class="form-control"
          type="search"
          placeholder="Search Here..."
          aria-label="Search"
          v-model="filter.search"
          @keydown.enter="search($event)"
          title="You can search by Customer ID, Name, Phone, & by Email"
        />
      </div>

      <div class="form-filter mb-0 mr-2 dropdown" @click="toggleDropdown">
          <img src="../../assets/images/shock-sjield/filter.png" class="mr-2">
          <span>Filter</span>

          <!-- Dropdown Menu -->
          <div class="dropdown-menu dropdown-menu-right px-3" :class="{ show: isDropdownOpen }" @click.stop>
            <!-- Close Icon -->
            <div class="d-flex justify-content-between align-items-center px-0">
              <span class="bold">Filter</span>
              <i class="fa fa-times" type="button" aria-label="Close" @click="toggleDropdown"></i>

            </div>

            <div class="px-2 py-1">
              <div class="row flex-column">
                <div class="">
                  <div class="form-group">
                    <div class="date-pick">
                      <input class="h-100" type="number"
                        placeholder="Customer Id"
                        v-model.number="filter.customer_id"
                        @keyup.enter="applyFilter">
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="form-group">
                    <div class="date-pick">
                      <input class="h-100"
                         placeholder="Customer Name"
                        v-model="filter.name"
                        @keyup.enter="applyFilter">
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="form-group">
                    <div class="date-pick">
                      <input
                        class="h-100"
                        placeholder="Phone"
                        v-model="filter.phone"
                        @keyup.enter="applyFilter"
                      /> 
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="form-group">
                    <div class="date-pick">
                      <input
                        class="h-100"
                        placeholder="Email"
                        v-model="filter.email"
                        @keyup.enter="applyFilter"
                      /> 
                    </div>
                  </div>
                </div>


                <div class="d-flex align-items-center">
                  <button class="custom-btn filter-btn m-0" @click="applyFilter">
                    Apply Filter
                  </button>
                  <button class="custom-btn filter-btn m-0 ml-4" @click="clearFilter">
                    Reset Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "CustomersTop",
  props: {
    count: Number,
    searched: String,
  },
  data() {
    return {
      filter: {
        customer_id: null,
        name: "",
        phone: "",
        email: "",
        state: "",
        search: "",
        },
        isDropdownOpen: false,
    };
  },
  watch: {
    'filter.search'(newVal) {
      if (newVal === "") {
        this.refreshListing(); // Call this method to refresh the listing when the search input is cleared
      }
    },
  },
  methods: {
    closeDateFilter() {
      this.dateFilterOpen = false;
    },
    toggleDropdown() {
      // if (this.dateFilterOpen) {
      //   this.dateFilterOpen = false; 
      // }
      this.isDropdownOpen = !this.isDropdownOpen; // Toggle the dropdown
    },
    
    search() {
      // Remove the '+' sign from the search term
      const sanitizedSearch = this.filter.search.replace('+', '');
      // Emit the sanitized search term
      this.$root.$emit("searchForCustomer", { search: sanitizedSearch });
    },
    refreshListing() {
      this.$root.$emit("searchForCustomer", { search: "" });
    },
    preservePlusSign(event) {
      // Keep the input clean of spaces
      this.filter.search = event.target.value.replace(/\s+/g, '');
    },
    
    clearFilter() {
      // Reset the filter object to its initial state
      this.filter = {
        customer_id: null,
        name: "",
        phone: "",
        email: "",
        state: "",
      };
      // Emit the cleared filter to the parent component
      this.$emit("clearFilter", { ...this.filter });
    },
    applyFilter() {
      // Explicitly assign input values to the filter object
      this.filter.customer_id = this.filter.customer_id || null;
      this.filter.name = this.filter.name || '';
      this.filter.phone = this.filter.phone || '';
      this.filter.email = this.filter.email || '';
      this.filter.state = this.filter.state || '';
      
      // Check if any filter field is filled before emitting
      if (
        this.filter.customer_id !== null ||
        this.filter.name !== '' ||
        this.filter.phone !== '' ||
        this.filter.email !== '' ||
        this.filter.state !== ''
      ) {
        // Emit the updated filter object to the parent component
        this.$emit("clearFilter", { ...this.filter });
      } else {
        console.log("No filter applied.");
        this.$emit("clearFilter", { ...this.filter });

      }
    },

  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 0.5em;
}
.date-pick {
  height: 45px;
  width: 100%;
  border: 1px solid var(--light-gray) !important;
  padding-top: auto;
  border-radius: 0.25rem;
  position: relative;
}
.date-pick input
  {
    width: 100%;
    border: none;
    padding: 5px;
}
.full-width-select {
  width: 100%;
}
.i {
  height: 20px;
  position: absolute;
  right: 0;
  padding-right: 3px;
}
.custom-select {
  border: 1px solid var(--light-gray) !important;
  border-radius: 0.25rem;
}

/* Custom Css for check inputs start */
/* .dis{
  display: contents!important;
} */
label {
  display: flex;
  align-items: center;
  /* color: #9e9e9e; */
  position: relative;
}

label > span {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  border: 2px solid #9e9e9e;
  margin-right: 10px;
  border-radius: 3px;
  transition: all 0.3s;
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input:checked + label > span {
  border: 7px solid #ffee00;
  animation: bounce 250ms;
}
input:checked + label > span::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 4px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checked-box 125ms 250ms forwards;
}
@keyframes checked-box {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate(0, 0) rotate(45deg);
  }
  33% {
    width: 4px;
    height: 0;
    border-color: #212121;
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    margin-top: 6px;
    width: 4px;
    height: 8px;
    border-color: #212121;
    transform: translate(0, -8px) rotate(45deg);
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.dropdown-menu-right span {
  font-weight: 500;
  color: var(--text-primary-color);
}

</style>
